import React, { useEffect, useRef, useState } from "react";
import { BACKEND_URL } from "../../../constants";
import 'react-slideshow-image/dist/styles.css'
import SectionMagazine4 from "components/Sections/SectionMagazine4"
import SectionMagazine8 from "components/Sections/SectionMagazine8"
import SectionGridPosts from "components/Sections/SectionGridPosts"
// import ReactWeather, { useOpenWeather } from 'react-open-weather';
import { Link } from "react-router-dom"
import 'reactjs-popup/dist/index.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {
  AdvertisingProvider,
  AdvertisingSlot,
  logVersionInfo
} from 'react-advertising';
import SectionMagazineFirst from "components/Sections/SectionMagazineFirst"
import WidgetCategoriesSecond from "components/WidgetCategoriesSecond/WidgetCategoriesSecond"
import SectionMagazineSecond5 from "components/Sections/SectionMagazineSecond5"
import SectionMagazineFirstSpecial from "components/Sections/SectionMagazineFirstSpecial"
import WidgetHeaderCategories from "components/WidgetHeaderCategories/WidgetHeaderCategories"
import cogoToast from "cogo-toast"
import WidgetVideo from "components/WidgetVideo/WidgetVideo"
import HeaderFilter from "components/Sections/HeaderFilter"
import SectionSliderAudio from "components/Sections/SectionSliderAudio"
import Heading6 from "components/Heading/Heading6"
import SectionSliderBandwidth from "components/Sections/SectionSliderBandwidth"
import SectionSliderPodcast from "components/Sections/SectionSliderPodcast";
import WidgetCategories from "components/WidgetCategories/WidgetCategories"
import SectionMagazineSecond4 from "components/Sections/SectionMagazineSecond4"


const getArticles = () => fetch(`${BACKEND_URL}/articles`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
});

const getAdverts = () => fetch(`${BACKEND_URL}/adverts`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
});


const getNewspappers = () => fetch(`${BACKEND_URL}/newspappers`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
});

const getLives = () => fetch(`${BACKEND_URL}/lives`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
});

const getPodcasts = () => fetch(`${BACKEND_URL}/podcasts`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
});

const getCategories = () => fetch(`${BACKEND_URL}/categories`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
});


const PageHomeDemo6 = () => {
  // const [hidden,setHidden] = useState(false)
  // const [show,setShow] = useState(true)
  const [live, setlive] = useState("")
  const [podcasts,setPodcasts] = useState([])
  const [adverts, setAdverts] = useState([])
  const [newspappers, setNewspappers] = useState([])
  // const [category, setCategory] = useState("")
  // const [subcategory, setSubCategory] = useState("")
  const [categoryid, setCategoryId] = useState(0)
  const [subcategoryid, setSubCategoryId] = useState(0)
  const [data,setData] = useState([])
  const [loading,setLoading]= useState(false)
  // const [subcategories,setSubCategories] = useState([])
  const [categories,setCategories] = useState([])
  // const[extended, setExtended] = useState(false)
  // const[option, setOption] = useState("Rozwiń")

  const newFiltered =  data
  .filter((post) => post.section_id == 1)

  useEffect(() => {
    setLoading(true)
    loading == true && cogoToast.loading("Trwa ładowanie artykułów...")
    // setTimeout(() => {
      Promise.allSettled([getArticles(), getCategories(), getPodcasts(), getLives(), getAdverts(), getNewspappers()])
        .then(async (response) => {
          const jsonResponse = response.map(res => res.value.json());
          return Promise.allSettled(jsonResponse);
          // return [await daysRes.value.json(), await trainingsRes.value.json(), await plansRes.value.json()];
        }).then(([articlesData, categoriesData, podcastsData, livesData, advertsData, newspappersData ]) => {
          if (!articlesData.value.hasOwnProperty("error")) {
            setData(articlesData.value);
            setLoading(false)
          }
          if (!livesData.value.hasOwnProperty("error")) {
            setlive(livesData.value.result);
            setLoading(false)
          }
          if (!podcastsData.value.hasOwnProperty("error")) {
            setPodcasts(podcastsData.value.result);
            setLoading(false)
          }
          if (!categoriesData.value.hasOwnProperty("error")) {
            setLoading(false)
            setCategories(categoriesData.value);
          }
          if (!advertsData.value.hasOwnProperty("error")) {
            setLoading(false)
            setAdverts(advertsData.value.result);
          }
          if (!newspappersData.value.hasOwnProperty("error")) {
            setLoading(false)
            setNewspappers(newspappersData.value.result);
          }
        }).catch(error => {
          console.error("error: ", error)
        })
  }, [])

  return (
    <div className="nc-PageHomeDemo6">
        <div>
        <div className="mobile-hidden"><WidgetCategoriesSecond posts={data} categories={categories} /></div>
        </div>
        {/* <div style={{marginBottom:"30px"}}><SectionMagazineAll className="container" heading="Aktualności" posts={data} /></div> */}
        <div style={{display:'flex', flexFlow:'column'}} className="container mobile-header">
        {/* <div style={{position:"relative", backgroundSize:"cover", height:"369px", backgroundImage:`url(${pilne})`}}>
        <h2 style={{position:'absolute', color:"white", fontFamily:"Redaction", fontSize:"56px",top:"67%", left:"23%"}}>{data[0]?.article_title}
        </h2>
        </div> */}
        <SectionSliderPodcast
          className="py-16"
          postCardName="card10"
          heading="Zobacz też"
          subHeading="Inne Artykuły"
          posts={podcasts}
        />
        {/* <hr className="mobile-line" style={{padding:"2px", left:'5%', width:"82%", top:"100px",position:"absolute", marginTop:"20px", backgroundColor:"#ac8502"}}></hr> */}
        <HeaderFilter heading="Aktualności"/>
        <hr className="mobile-line" style={{padding:"2px", marginTop:"20px", backgroundColor:"#ac8502"}}></hr>
        </div>
        <div className="container">
        <SectionSliderBandwidth
          className="py-16"
          postCardName="card10"
          width={1600}
          heading="Zobacz też"
          subHeading="Inne Artykuły"
          posts={newFiltered
            .slice(newFiltered.length - 5, newFiltered.length)
            .reverse()
          }
        />
        </div>
        <div className="box-main grid-container hr container">
          <div className="box-width-section">
          <div className="box-width grid-item w-full">
            <SectionMagazineFirst heading="Polecane" posts={data}/></div>
              {/* <hr className="divider"></hr> */}
              {/* <div className="box-width grid-item w-full">
                <WidgetCategoriesFirst posts={data} categories={categories} />
              </div> */}
          </div>
              <div className="half-box">
              <WidgetHeaderCategories live={live} posts={data} categories={categories} />
                {/* <WidgetVideo height={300} width={350} posts={data} categories={categories} /> */}
                <Heading6>Radio Nysa</Heading6>
                {/* <button className="subcategory-btn" style={{backgroundColor:'red', marginRight:"auto", fontSize:"20px", color:"white", marginTop:"10px", marginBottom:'10px', width:"42%", border:"none", padding:"3px 3px 3px 3px"}}>Radio Nysa</button> */}
                <AudioPlayer 
                src="https://stream2.nadaje.com:18066" 
                onPlay={() => console.log("onPlay")}
                />
                 {/* <AudioPlayer src="http://stream2.nadaje.com:18066" /> */}
                 <Link to={adverts[0]?.link}><img style={{height:"448px", maxWidth:"499px"}} alt="reklama" src={adverts[0]?.photo}/></Link>
              </div>
            </div>
            <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
            {/* <div className="box-main grid-container hr container">
        <SectionMagazine1 posts={data.reverse()} />
        </div> */}
        {/* <hr className="desktop-line" style={{padding:"0.5px", marginTop:"20px", backgroundColor:"black"}}></hr> */}
        <div className="kitchen-box" style={{display:"flex",width:"100%", backgroundColor:"#f0efec", alignItems:"center", gap:"35px"}}>
            <div className="container" style={{paddingBottom:"3rem"}}>
              <SectionMagazineSecond5 subcategory_id={5} category_id={5} heading="Nysa i okolice" posts={data} />
            </div>
        </div>
        {/* <div className="kitchen-box" style={{display:"flex",width:"100%", backgroundColor:"#f0efec", alignItems:"center", gap:"35px"}}>
            <div className="container" style={{paddingBottom:"2rem"}}>
              <SectionMagazineSecond5 subcategory_id={5} category_id={5} heading="Aktualności z regionu" posts={data} />
            </div>
        </div> */}
        <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
      {/* </div> */}
      <div className="box-main hr container">
      <div className="box-width-section">
            <div className="box-width">
              <SectionMagazineFirstSpecial subcategory_id={subcategoryid} category_id={categoryid} heading="Polecane" category="Sport" posts={data}/></div>
              {/* <hr className="divider"></hr> */}
              {/* <div className="box-width"><WidgetCategoriesThird posts={data} categories={categories} />
              </div> */}
              </div>
              <div className="half-box">
                <Link to={adverts[2]?.link}><img src={adverts[2]?.photo}/></Link>
                {/* <WidgetVideosCategories live={live} posts={data} categories={categories} /> */}
                
                <WidgetVideo height={350} width={350} podcast={podcasts.filter((podcast) => podcast.showHome == 1)} categories={categories} />
                </div>
              </div>
              <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
      <div className="relative overflow-hidden">
        <div className="relative">
          <div style={{backgroundColor: "rgb(240, 239, 236)"}}>
          <div className="container" style={{paddingBottom:"30px"}}>
              <SectionMagazine4 subcategory_id={subcategoryid} category_id={categoryid} heading="Wydarzenia dnia" category="Gospodarka" posts={data} />
            </div>
          </div>
          <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
          <div className="kitchen-box container">
              <div className="half-box"><SectionMagazine8 posts={data}/></div>
              <div className="half-box"><WidgetCategories posts={data} categories={categories} /></div>
          </div>
          <div style={{paddingBottom:"2rem", paddingTop:'2rem', backgroundColor:'black'}}>
              <div className="container">
                <SectionGridPosts
                  headingIsCenter
                  postCardName="card10V2"
                  heading="Najnowsze video"
                  subHeading="Hover on the post card and preview video 🥡"
                  posts={data
                    .filter((post) => post.type == "wideo")
                    .filter((post) => post.status == 1)}
                  gridClass="sm:grid-cols-2 lg:grid-cols-3"
                />
              </div>
            </div>
          {/* <div style={{marginBottom:"2rem"}}></div> */}
          {/* <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr> */}
          <Link to={adverts[1]?.link}><AdvertisingProvider>
        <div>
          <div style={{backgroundImage:`url(${adverts[1]?.photo})`}} className="image">
    <div style={{display:'flex',justifyContent:"end", paddingRight:"35px", gap:"8px", paddingTop:"10%", alignItems:"center"}}>
    </div>
    </div>
  </div>
          </AdvertisingProvider>
          </Link>
          <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
          <SectionMagazineSecond4 className="container" subcategory_id={2} category_id={1}  heading="Polityka na świecie" posts={data} />
          {/* <div className="container">
          <img style={{height:"448px"}} alt="reklama-mentoring" src={reklama_mentoring}/>
          </div> */}
          <div style={{marginBottom:"1rem"}}></div>
        </div>
          </div>
          <div className="relative container">
           <SectionSliderAudio
           postCardName="card10"
           heading="Sea travel enthusiast"
           subHeading="Over 218 articles about sea travel"
           podcasts={podcasts}
        />
        </div>
        {/* <div className="kitchen-box" style={{display:"flex",width:"100%", justifyContent:'center', backgroundColor:"#f0efec", alignItems:"center", gap:"35px"}}>
            <div style={{paddingBottom:"2rem"}}>
            <SectionMagazineAll className="container" heading="Aktualności" posts={data} />
            </div>
        </div> */}
        {/* <div style={{marginBottom:"0rem"}} className="relative container">
        </div> */}
      </div>
  );
};

export default PageHomeDemo6;

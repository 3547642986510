import React, { FC, useState } from "react";
import Heading from "components/Heading/Heading";
import Card4 from "components/Card4/Card4";
import Card7 from "components/Card7/Card7";
import { PostDataType } from "data/types";
import Card9 from "components/Card9/Card9";
import Card10 from "components/Card10/Card10";
import Card11 from "components/Card11/Card11";
import Card10V2 from "components/Card10/Card10V2";
import MySlider from "components/MySlider";

const SectionSliderBandwidth = ({
  heading,
  subHeading,
  width,
  className = "",
  posts,
  postCardName = "card4",
}) => {
  let CardComponent = Card4;

  // switch (postCardName) {
  //   case "card4":
  //     CardComponent = Card4;
  //     break;
  //   case "card7":
  //     CardComponent = Card7;
  //     break;
  //   case "card9":
  //     CardComponent = Card9;
  //     break;
  //   case "card10":
  //     CardComponent = Card10;
  //     break;
  //   case "card10V2":
  //     CardComponent = Card10V2;
  //     break;
  //   case "card11":
  //     CardComponent = Card11;
  //     break;

  //   default:
  //     break;
  // }

  return (
    <div style={{backgroundColor:'red', paddingBottom:'10px', padding:'0px 10px 0px'}} className={`nc-SectionSliderPosts red-bandwidth ${className}`}>
      <MySlider
        width={width}
        data={posts}
      />
    </div>
  );
};

export default SectionSliderBandwidth;

import React, { FC, useState } from "react";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import { PostDataType } from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostFeaturedMedia from "components/PostFeaturedMedia/PostFeaturedMedia";
import PostCardMetaV2 from "components/PostCardMeta/PostCardMetaV2";
import Link from "components/Link";
import Badge from "components/Badge/Badge"


const Card10V2 = ({ className = "h-full", post }) => {
  const { href, categories, video, image, title, name } = post;
  const [isHover, setIsHover] = useState(false);

  return (
    <div
    style={{display:'grid', alignContent:"space-between"}}
      className={`nc-Card10V2 relative ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
        <div className="space-y-2.5 mt-4">
        <PostCardMetaV2 meta={post} />
      </div>
      {/* <span className="category-titles" style={{color:"red",fontWeight:700}}>{title}</span> */}
      <span className="category-titles" style={{color:"red",fontWeight:700}}>{name} - {title} </span>
      <div className="block group flex-shrink-0 relative w-full aspect-w-16 aspect-h-12 sm:aspect-h-9 overflow-hidden z-0">
        <div>
        <video poster={image} controls src={video}></video>
        </div>

        {/* <Link
          href={href}
          className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 transition-opacity"
        ></Link> */}
      </div>
      {/* <div className="absolute top-3 inset-x-3 flex justify-between items-start space-x-4">
      <Badge
          // className={itemClass}
          color={categories?.title == "Polityka"? "blue": categories?.title == "Sport" ? "red" : categories?.title == "Społeczeństwo" ? "green": categories?.title == "Gospodarka" ? "orange": "orange"}         
          key={id}
          name={title}
          href={`/${categories?.title}/${categories?.name}/${parseInt(categories?.subcategory_id)}/${categories?.category_id}`}
        />
      </div> */}
    </div>
  );
};

export default Card10V2;

import CardCategory1 from "components/CardCategory1/CardCategory1";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";
import React, { FC } from "react";

const WidgetCategories = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  categories,
  posts,
}) => {
  const filtered = posts.filter((post) => post.section_id == 7)
  return (
    <div
    style={{paddingTop:'2rem'}}
      className={`nc-WidgetCategories overflow-hidden ${className}`}
    >
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {/* <WidgetHeading1
        title="Popularne artykuły"
      /> */}
       <button style={{backgroundColor:'red', fontSize:"20px", color:"white", width:"50%", border:"none", padding:"3px 3px 3px 3px"}}>Popularne artykuły</button>
          {filtered
          .slice(filtered.length - 4, filtered.length)
          .map((post) => (
            <CardCategory1
              className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={post.id}
              id={post.id}
              video={post?.video}
              type={post?.type}
              image={post?.image}
              category={post?.name}
              subcategory={post?.title.toLowerCase()}
              category_id={post?.category_id}
              subcategory_id={post?.subcategory_id}
              name={post?.article_title}
              size="normal"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WidgetCategories;
import React, { useEffect, useRef, useState } from "react";
import SectionMagazine12 from "components/Sections/SectionMagazine12";
import { BACKEND_URL } from "../../../constants";
import SectionAdverts from "components/Sections/SectionAdverts"

const PageGetAllAdverts = () => {
  const [data,setData] = useState([])
  let user = sessionStorage.getItem("user")
  const initialized = useRef(false);
  useEffect(() => {
      fetch(`${BACKEND_URL}/get-adverts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, [JSON.parse(user)?.user.id, user]);


  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <div className="relative overflow-hidden">
        <div className="container relative">
        <h1 style={{marginTop:"50px",marginBottom:"50px"}} className="text-2xl md:text-3xl lg:text-4xl font-semibold">Wszystkie Reklamy</h1>
          <SectionAdverts posts={data} />
        </div> 
      </div>
    </div>
  );
};

export default PageGetAllAdverts ;

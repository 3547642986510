import React, { FC, useEffect, useRef, useState } from "react";
import Card8 from "components/Card8/Card8";
import { SectionMagazine1Props } from "./SectionMagazine1";
import HeaderFilter from "./HeaderFilter";
import Card9 from "components/Card9/Card9";
import { BACKEND_URL } from "constants"
import { Link } from "react-router-dom"
import HeaderFilter2 from "./HeaderFilter2"
import CardNewspapper from "components/CardNewspapper/CardNewspapper"

// export interface SectionMagazine4Props extends SectionMagazine1Props {}

const SectionNewspappers = ({
  posts,
  className = "",
  category_id,
  category,
  subcategory_id,
  heading = "Latest Articles 🎈",
}) => {

  // const postWithStatus1 = posts
  //   .find((item) => item.category_id === category_id && item.subcategory_id === subcategory_id && item.status === 1);

  return (
      <div className={`nc-SectionMagazine4 ${className}`}>
       <div className="mobile-header"><HeaderFilter2 heading={heading} /></div> 
       <button className="subcategory-btn" style={{backgroundColor:'red', color:"white", marginTop:"10px", fontSize:"20px", marginBottom:'10px', width:"86%", border:"none", padding:"3px 3px 3px 3px"}}>Gazeta</button>
  
        {!posts?.length && <span>Nothing we found!</span>}
        <div 
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 md:gap-8"
        >
          {posts
            // .reverse()
            .map((item) => {
            return (
              <div style={{display:"flex", flexFlow:"column", justifyContent:'center', alignItems:"center", width:"100%"}} key={item.id}>
                <Link to={item?.pdf} style={{color:"black"}}>
                <h3 style={{fontSize:"25px"}} className="mobile-font">{item?.title}</h3>
                </Link>
                {/* <span className="category-titles"  style={{color:"red",fontWeight:700}}><b>{item?.title}</b></span> */}
                <CardNewspapper key={item.id} post={item} />
                <hr className="mobile-line" style={{padding:"2px", marginTop:"20px", backgroundColor:"#ac8502"}}></hr>
              </div>
            )})}
            {/* {posts[10] && <Card8 category={category} className="sm:col-span-2" post={posts[10]} />} */}
        </div>
      </div>
    );
};

export default SectionNewspappers;

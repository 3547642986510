import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import cogoToast from "cogo-toast";
import Input from "components/Input/Input";
import { BACKEND_URL } from "../../../constants";
import PublitioAPI from 'publitio_js_sdk'
import ButtonPrimary from "components/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";
import Datetime from 'react-datetime';
import { 
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  EditorProvider,
  Editor,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar,
} from 'react-simple-wysiwyg';
import ClipLoader from "react-spinners/ClipLoader";
import 'moment/locale/pl';
import "react-datetime/css/react-datetime.css";
import Layout from "../layout";

const PageEditAdverts = () => {
  const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')
  const navigate = useNavigate()
  const params = useParams()
  const [loading,setLoading] = useState(false)
  const [adverts,setAdverts] = useState([])
  let user = sessionStorage.getItem("user")
  const initialized = useRef(false);
  const {
    register,
    getValues,
    // formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
        image: "",
        link:""
    },
  });
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/advert/${params?.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setAdverts(response[0])
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, [JSON.parse(user)?.user.id, user]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setAdverts({...adverts, image: URL.createObjectURL(event.target.files[0] )});
    }
   }

  const editAdverts = async (e) => {
    e.preventDefault();
    const data = getValues();
    setLoading(true)
    let photo;
    if(data.image[0] !== undefined){
      photo = await publitio.uploadFile(data.image[0], 'file')
    }
    setTimeout(() => {
      fetch(`${BACKEND_URL}/edit-adverts`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: params.id,
          user_id: adverts?.user_id,
          image: photo !== undefined ? photo.url_preview: adverts.photo,
          link: data.link == "" ? adverts.link : data.link
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Reklama została zaaktulizowana");
            setLoading(false)
            window.location.reload()
          } 
        })
        .catch((error) => console.error("error: ", error));
    }, [2000]);
  };
  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
        <Heading2>Edycja reklamy</Heading2>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        {/* FORM */}
        <form className="grid grid-cols-1 gap-6" action="#" method="post">
          <h3 style={{fontSize:"20px"}}>{adverts?.name}</h3>
          <label className="block">
            <Input {...register("image", {
              onChange: onImageChange
            })} type="file" max-size="200000" className="mt-1" />
            <img width={100} alt="model" src={adverts?.photo}/>
          </label>
          <label className="block">
            <Input
             {...register("link", {
              required: { value: true, message: "Pole wymagane" },
            })}
              type="text"
              placeholder={adverts?.link}
              className="mt-1"
            />
          </label>
        </form>
        <ButtonPrimary onClick={editAdverts}>Zaaktualizuj reklamę</ButtonPrimary>
        {loading == true && 
        <div style={{marginTop:'40px'}}>
          <p style={{marginBottom:'20px'}}>Uwaga edycja reklamy może chwile potrwać zależności od wielkości wysłanego zdjęcia.</p>
          <ClipLoader color="black" size={50} aria-label="Loading Spinner" data-testid="loader"/>
          </div>}
      </div>
    </Layout>
  );
};

export default PageEditAdverts;
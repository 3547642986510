import React, { FC, useState } from "react";
import Card8 from "components/Card8/Card8";
// import { SectionMagazine1Props } from "./SectionMagazine1";
import HeaderFilter from "./HeaderFilter";
import Card9 from "components/Card9/Card9";
import CardSubCategory from "components/CardSubCategory/CardSubCategory"
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom"
import WidgetVideo from "components/WidgetVideo/WidgetVideo"
import SectionSliderBandwidth from "./SectionSliderBandwidth"
// import ReactPaginate from "https://cdn.skypack.dev/react-paginate@7.1.3";

// export interface SectionMagazine4Props extends SectionMagazine1Props {}

const SectionMagazineSubCategory4 = ({
  posts,
  className = "",
  category_id,
  subcategory,
  category,
  subcategory_id,
  heading = subcategory
}) => {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 5;
  const items = posts
  .filter((item) => {
    return item.subcategory_id === +subcategory_id && item.category_id === +category_id
  });
  const newFiltered = posts
  .filter((item) => {
    return item.category_id == 5 && item.subcategory_id == subcategory_id
  });

  // console.log(items, "items", subcategory_id, category_id)
  const currentItems = items.reverse()
  // .slice(itemOffset, endOffset)
  const pageCount = Math.ceil(items.length / 2);
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * 5) % items.length;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      setItemOffset(newOffset);
    };

  
  return (
    <div className={`nc-SectionMagazine4 ${className}`}>
       <div className="container mobile-header"><HeaderFilter heading={heading === "Spoleczenstwo"? "Społeczeństwo": heading == "Aktualnosci" ? "Aktualności": heading} /></div>
       <div style={{paddingBottom:"15px"}}>
        <button className="subcategory-btn" style={{backgroundColor:'red', margin:"0 auto", fontSize:'20px', color:"white", width:"62%", border:"none", padding:"3px 3px 3px 3px"}}>{heading === "SPOLECZENSTWO"? "SPOŁECZEŃSTWO": heading}</button>
       </div>
      {!posts?.length && subcategory_id < 6 &&  <span>Trwa pobieranie artykułów...</span>}
      {currentItems.length == 0 && subcategory_id >=6 && <h3 style={{fontSize:"30px", fontFamily:"Roboto"}}>Wkrótce</h3>}
      <div>
      <div className="box-newest" style={{display:'flex', flexFlow:'column'}}>
      {category_id == 5 && 
      <SectionSliderBandwidth
          className="py-16"
          width={1000}
          postCardName="card10"
          heading="Zobacz też"
          subHeading="Inne Artykuły"
          posts={newFiltered
            .slice(newFiltered.length - 4, newFiltered.length)
            .reverse()
          }
        />
        }
        {currentItems
        .sort(() => function(a, b){return a - b})
          .map((item) => {
            return(
              <div className="articles-box" key={item.id}>
              <div style={{display:"flex", flexFlow:"column", gap:"20px"}}>
              <Link to={`/${item?.title.toLowerCase()}/${item.name === "Świat" ? "swiat": item?.name.toLowerCase()}/${item?.category_id}/${item?.subcategory_id}/artykuł/${item?.id}`} style={{color:"black"}}>
              <h3 style={{fontSize:"29px", lineHeight:'30px', fontFamily:"Redaction", fontWeight:"bold" , fontStyle:"normal",maxWidth:'500px'}}>{item.article_title}</h3>
              </Link>
              <span style={{color:"red",fontWeight:"300", fontSize:"12px"}}><b>{item.title.toUpperCase()}</b></span>
              {/* <p className="subcategory-paragraf"  dangerouslySetInnerHTML={{__html: item?.long_description}}></p> */}
              <p className="subcategory-paragraf" >{item?.description}</p>
              <span style={{color:"rgb(172, 133, 2)"}}>{item.name}</span>
              <span className="category-titles" style={{color:"grey",fontWeight:700}}>{item?.date != null && new Date(item?.date).toLocaleString()} </span>
              </div>
              <CardSubCategory key={item.id} post={item} />
              <hr className="mobile-line" style={{padding:"2px", marginTop:"20px", backgroundColor:"#ac8502"}}></hr>
              </div>
            )
          })}
      </div>
      {/* <div style={{width:"39%"}} className="half-box-special">
        <WidgetVideo height={255} width={400} /></div> */}
      </div>
      <div style={{marginTop:"20px"}}>
      {/* <ReactPaginate
        nextLabel="następna >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< poprzednia"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      /> */}
      </div>
    </div>
  );
};

export default SectionMagazineSubCategory4;

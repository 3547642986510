import React, { FC } from "react";
import Avatar from "components/Avatar/Avatar";
import { PostDataType } from "data/types";
import Link from "components/Link";

const PostCardMetaV2 = ({
  meta,
  hiddenAvatar = false,
  className = "leading-none text-xs",
  titleClassName = "text-base",
  avatarSize = "h-9 w-9 text-base",
}) => {
  const { date, author, title, article_title, description  } = meta;
  return (
    <div
      className={`nc-PostCardMetaV2 inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
    >
      <div className="relative flex items-center space-x-2">
        {/* {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={author.avatar}
            userName={author.displayName}
          />
        )} */}
        <div>
          <h2 style={{fontSize:"28px" , color:"white"}} className={`block font-semibold ${titleClassName}`}>
            {/* <h3 href="" className="line-clamp-1 texty:hover"> */}
            <Link style={{fontFamily:'Redaction'}} href={`/${meta?.title.toLowerCase()}/${meta?.name === "Świat" ? "swiat" : meta?.name.toLowerCase()}/${meta?.category_id}/${meta?.subcategory_id}/artykuł/${meta?.id}`} className="flex mt-1.5 link-hover"> {article_title}</Link>
            {/* </h3> */}
          </h2>
          <p className="article-descy" style={{textAlign:'justify', marginTop:"10px", color:"white"}}>{description}</p>
          <span className="category-titles" style={{color:"white",fontWeight:700}}>{date != null && new Date(date).toLocaleString()} </span>
          <Link style={{marginBottom:'10px'}} className="flex mt-1.5">
            {/* <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
           {description}
            </span> */}
            {/* <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
              
            </span> */}
            {/* <span className="text-neutral-500 dark:text-neutral-400 font-normal">
              {date}
            </span> */}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostCardMetaV2;

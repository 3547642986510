import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import cogoToast from "cogo-toast";
import Input from "components/Input/Input";
import { BACKEND_URL } from "../../../constants";
import PublitioAPI from 'publitio_js_sdk'
import ButtonPrimary from "components/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";
import Datetime from 'react-datetime';
import { 
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  EditorProvider,
  Editor,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar,
} from 'react-simple-wysiwyg';
import ClipLoader from "react-spinners/ClipLoader";
import 'moment/locale/pl';
import "react-datetime/css/react-datetime.css";
import Layout from "../layout";

const PageEditArticles = () => {
  const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')
  const navigate = useNavigate()
  const params = useParams()
  const [date,setDate] = useState("")
  const [loading,setLoading] = useState(false)
  const [longDescription,setLongDescription] = useState('Dodaj długi opis')
  const [sections,setSections] = useState([])
  const [categories,setCategories] = useState([])
  const [categoryid, setCategoryId] = useState(0)
  const [sectionid, setSectionId] = useState(null)
  const [subcategoryid, setSubCategoryId] = useState(0)
  const [subcategories,setSubCategories] = useState([])
  const [articles,setArticles] = useState([])
  let user = sessionStorage.getItem("user")
  const initialized = useRef(false);
  const {
    register,
    getValues,
    // formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      title: "",
      description:"",
      // long_description:"",
      image: "",
    },
  });
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/article/${params?.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setArticles(response[0])
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, [JSON.parse(user)?.user.id, user]);


  useEffect(() => {
      fetch(`${BACKEND_URL}/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setCategories(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, []);



  useEffect(() => {
    // if (!initialized.current) {
    //   initialized.current = true;
      fetch(`${BACKEND_URL}/sections`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setSections(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, []);


  useEffect(() => {
      fetch(`${BACKEND_URL}/subcategories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setSubCategories(response.result)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, [JSON.parse(user)?.user.id, user]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setArticles({...articles, image: URL.createObjectURL(event.target.files[0] )});
    }
   }

   const onChangeDate = (eventDate) => {
    setDate(eventDate)
  }

   const onChange = (e) => {
    if(e.target.name === "kategorie"){
      setCategoryId(e.target[e.target.selectedIndex].id)
    } 
    else if(e.target.name == "sekcje"){
      setSectionId(e.target[e.target.selectedIndex].id)
    }
    else{
      setSubCategoryId(e.target[e.target.selectedIndex].id)
    }
  }

  const editArticles = async (e) => {
    e.preventDefault();
    const data = getValues();
    setLoading(true)
    let photo;
    console.log(data.image[0], "images")
    if(data.image[0] !== undefined){
      photo = await publitio.uploadFile(data.image[0], 'file')
    }
    console.log(photo,"photo")
    setTimeout(() => {
      fetch(`${BACKEND_URL}/edit-articles`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: params.id,
          user_id: articles?.user_id,
          date: date === "" ? articles?.date : date.toDate(),
          title: data.title === "" ? articles?.article_title : data?.title,
          description: data.description === "" ? articles?.description : data?.description,
          long_description:longDescription === "Dodaj długi opis" ? articles.long_description : longDescription,
          category_id: categoryid === 0 ? articles.category_id :categoryid,
          section_id: sectionid == null || sectionid == "" ? articles.section_id: sectionid,
          subcategory_id: subcategoryid === 0 ? articles.subcategory_id :subcategoryid,
          image: photo !== undefined ? photo.url_short: articles.image
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Artykuł został zaaktulizowany");
            setLoading(false)
            navigate("/");
          } 
        })
        .catch((error) => console.error("error: ", error));
    }, [2000]);
  };
  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
        <Heading2>Edycja Artykuł</Heading2>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        {/* FORM */}
        <form className="grid grid-cols-1 gap-6" action="#" method="post">
          <label className="block">
            Tytuł artykułu: {articles?.article_title}
            <Input
             {...register("title", {
            //   required: { value: true, message: "Pole wymagane" },
            //   maxLength: 20,
            //   minLength: 3,
            })}
              type="text"
              placeholder={articles?.article_title}
              className="mt-1"
            />
          </label>
          <label className="block">
          Krótki opis: {articles?.description}
            <Input
             {...register("description", {
            //   required: { value: true, message: "Pole wymagane" },
            //   maxLength: 20,
            //   minLength: 3,
            })}
              type="text"
              placeholder={articles?.description}
              className="mt-1"
            />
          </label>
          <label className="block">
          Długi opis artykułu: <p dangerouslySetInnerHTML={{__html:articles?.long_description}}></p>
          <EditorProvider>
          <Editor value={longDescription} onChange={(e) => setLongDescription(e.target.value)}>
        <Toolbar>
        <BtnUndo />
          <BtnRedo />
          <Separator />
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          {/* <BtnStrikeThrough /> */}
          <Separator />
          {/* <BtnNumberedList /> */}
          {/* <BtnBulletList /> */}
          <Separator />
          {/* <BtnLink /> */}
          {/* <BtnClearFormatting /> */}
          {/* <HtmlButton /> */}
          <Separator />
          <BtnStyles />
        </Toolbar>
      </Editor>
    </EditorProvider>
          </label>
          <label className="block">
            <h2 style={{color:"black"}}>{articles?.name}</h2>
            <select name="kategorie" onChange={onChange} style={{borderRadius:"9999px"}}>
            <option>Wybierz kategorie</option>
            {categories.map((category, index) => {
              return <option id={category.id} key={category.id} value={category.name}>{category.name}</option>
            })}
            </select>
          </label>
          <label className="block">
          <h2 style={{color:"black"}}>{articles?.title}</h2>
            <select name="podkategorie" onChange={onChange} style={{borderRadius:"9999px"}}>
            <option>Wybierz podkategorie</option>
            {subcategories.map((subcategory, index) => {
              return <option id={subcategory.id} key={subcategory.id} value={subcategory.title}>{subcategory.title}</option>
            })}
            </select>
          </label>
         <label className="block">
            <select name="sekcje" onChange={onChange} style={{borderRadius:"9999px"}}>
            <option>Wybierz sekcje</option>
            {sections
            .filter((section) => section.id == 1 || section.id == 2 || section.id == 9 || section.id == 3 || section.id == 4 || section.id == 5 || section.id == 6 || section.id == 7 || section.id == 8 )
            .map((section, index) => {
              return <option id={section.id} key={section.id} value={section.sectionName}>{section.sectionName}</option>
            })}
            </select>
          </label>
          <label>
            <h2>{new Date(articles?.date).toLocaleString()}</h2>
          <Datetime className="form-control" locale="pl" onChange={onChangeDate} />
          </label>
          <label className="block">
            <Input {...register("image", {
              onChange: onImageChange
            })} type="file" max-size="200000" className="mt-1" />
            <img width={100} alt="model" src={articles?.image}/>
          </label>
        </form>
        <ButtonPrimary onClick={editArticles}>Zaaktualizuj artykuł</ButtonPrimary>
        {loading == true && 
        <div style={{marginTop:'40px'}}>
          <p style={{marginBottom:'20px'}}>Uwaga edycja artykułu może chwile potrwać zależności od wielkości wysłanego zdjęcia.</p>
          <ClipLoader color="black" size={50} aria-label="Loading Spinner" data-testid="loader"/>
          </div>}
      </div>
    </Layout>
  );
};

export default PageEditArticles;
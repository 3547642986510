import React, { useEffect, useRef, useState } from "react";
import pdf from "./ResPublica styczen 2025 ok.pdf"

const PageGazetaJanuary = () => {
  useEffect(() => {
    window.location.href = pdf
  })
};

export default PageGazetaJanuary

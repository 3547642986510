import React, { useEffect, useRef, useState } from "react";
import SectionMagazine12 from "components/Sections/SectionMagazine12";
import { BACKEND_URL } from "../../../constants";
//

const PageGetAllArticles = () => {
  const [data,setData] = useState([])
  const [podcasts, setPodcasts] = useState([])
  let user = sessionStorage.getItem("user")
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/all-articles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, [JSON.parse(user)?.user.id, user]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/podcasts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setPodcasts(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, [JSON.parse(user)?.user.id, user]);

  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <div className="relative overflow-hidden">
        <div className="container relative">
        <h1 style={{marginTop:"50px",marginBottom:"50px"}} className="text-2xl md:text-3xl lg:text-4xl font-semibold">Wszystkie artykuły</h1>
          <SectionMagazine12 podcasts={podcasts} posts={data} />
        </div> 
      </div>
    </div>
  );
};

export default PageGetAllArticles;

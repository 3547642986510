import { Slide } from 'react-slideshow-image';
import kuchnia from "../../../images/kuchniaregionalna.jpg";
import zurek from "../../../images/zorek.jpeg";
import roladka from "../../../images/roladaslaska.jpeg";
import 'react-slideshow-image/dist/styles.css'
import { Link } from 'react-router-dom'
import HeaderFilter from 'components/Sections/HeaderFilter'
import { useEffect, useRef, useState } from 'react'
import { BACKEND_URL } from 'constants'
import SectionMagazineHealth from 'components/Sections/SectionMagazineHealth'
import SectionMagazineSecond5 from 'components/Sections/SectionMagazineSecond5'
import SectionNewspappers from 'components/Sections/SectionNewspappers'
import SectionGridMovies from 'components/Sections/SectionGridMovies'

const PageFilmy = () => {
  const[data,setData] = useState([])
  const initialized = useRef(false);

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '250px'
  }


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/movies`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response.result)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, []);

  return (
    <div className={`nc-PageArchiveAudio `}>
      {/* HEADER */}
      {/* <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
      <div className="container" style={{marginTop:"37px", textAlign:"left"}}>
        <HeaderFilter heading="Zdrowie" />
        </div>
      </div> */}
      {/* ====================== END HEADER ====================== */}

      <div style={{paddingBottom:"2rem", paddingTop:'2rem', backgroundColor:'black'}}>
              <div className="container">
                <SectionGridMovies
                  headingIsCenter
                  postCardName="card10V2"
                  heading="Wszystkie filmy"
                  subHeading="Hover on the post card and preview video 🥡"
                  posts={data}
                  gridClass="sm:grid-cols-2 lg:grid-cols-3"
                />
              </div>
            </div>
    </div>
  );
};

export default PageFilmy

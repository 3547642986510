import React, { FC, useEffect, useRef, useState } from "react";
import Card8 from "components/Card8/Card8";
import { SectionMagazine1Props } from "./SectionMagazine1";
import HeaderFilter from "./HeaderFilter";
import Card9 from "components/Card9/Card9";
import { BACKEND_URL } from "constants"
import Card8Special from "components/Card8Special/Card8Special"
import { Link } from "react-router-dom"

// export interface SectionMagazine4Props extends SectionMagazine1Props {}

const SectionMagazineFirstSpecial = ({
  posts,
  className = "",
  category_id,
  category,
  subcategory_id,
  heading = "Latest Articles 🎈",
}) => {

  // const postWithStatus1 = posts
  //   .find((item) => item.category_id === category_id && item.subcategory_id === subcategory_id && item.status === 1);

    const filtered = posts.filter((post) => post.section_id == 4 )

  return (
    <div style={{display:"flex", flexFlow:"column"}} className={`nc-SectionMagazine4 ${className}`}>
      <div className="mobile-divider"></div>
    {/* <button className="subcategory-btn" style={{backgroundColor:'red', fontSize:'20px', color:"white", width:"38%", border:"none", padding:"3px 3px 3px 3px"}}>Nysa i okolice</button> */}
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 md:gap-8">
   {filtered
   .slice(filtered.length - 2, filtered.length)
   .reverse()
   .map((item) => {
     return(
             <div key={item.id} style={{display:"flex", flexFlow:"column", justifyContent:"space-between"}}>
             <div style={{display:"flex", flexFlow:'column', gap:"20px"}}>
             <Link className="article-titles" to={`/${item?.title.toLowerCase()}/${item?.name === "Świat" ? "swiat" : item?.name.toLowerCase()}/${item?.category_id}/${item?.subcategory_id}/artykuł/${item?.id}`}>
             <h3 className="mobile-font">{item?.article_title}</h3>
             </Link>
             {/* <span className="category-titles" style={{color:"red",fontWeight:700}}><b>{item?.title}</b></span> */}
             <span className="category-titles" style={{color:"red",fontWeight:700}}>{item?.name} - {item?.title} </span>
             <p className="article-descy" style={{textAlign: "justify"}}>{item?.description.slice(0,80)}...</p>
             <span className="category-titles" style={{color:"grey",fontWeight:700}}>{item?.date != null && new Date(item?.date).toLocaleString()} </span>
             </div>
           <Card8Special category={category} title={item?.title.toLowerCase()} name={item?.name.toLowerCase()} category_id={item?.category_id} subcategory_id={item?.subcategory_id} className="sm:col-span-2" post={item} />
           <hr className="mobile-line" style={{padding:"2px", marginTop:"20px", backgroundColor:"#ac8502"}}></hr>
           </div>
     )})}
     </div>

   {!posts?.length && <span>Nie znaleziono artykułów!</span>}
 </div>
    );
};

export default SectionMagazineFirstSpecial;

import React, { FC } from "react";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import { PostDataType } from "data/types";
import CardAuthor2 from "components/CardAuthor2/CardAuthor2";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import Image from "components/Image";


const Card4Kitchen = ({ className = "h-full", post }) => {
  const { article_title,id, href, image, categories, author, date, readingTime } =
    post;

  return (
    <div
      className={`nc-Card4 relative flex flex-col group bg-white dark:bg-neutral-900 ${className}`}
    >
      <span className="block flex-shrink-0 relative w-full aspect-w-16 aspect-h-9 overflow-hidden">
      <button onClick={() => window.location.href=`/artykuł/${post?.id}`} style={{color:"black",maxWidth:"324px"}} className="line-clamp-3" title={post?.article_title}>
        <Image
          fill
          className="object-cover"
          alt=""
          sizes="(max-width: 600px) 480px, 800px"
          src={image}
        />
        </button>
      </span>

      {/* <Link href={`/single/${id}`}  className="absolute inset-0"></Link> */}

      {/* <div className="p-4 flex flex-col flex-grow">
        <div className="space-y-2.5 mb-4">
          <CategoryBadgeList categories={categories} />
          <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100 ">
            <Link href={`/single/${id}`}  className="line-clamp-2" title={article_title}>
              {article_title}
            </Link>
          </h2>
        </div>
      </div> */}
    </div>
  );
};

export default Card4Kitchen;

import React, { useEffect, useRef, useState } from "react";
import NcImage from "components/NcImage/NcImage";
import SingleHeader from "app/(singles)/SingleHeader";
import Layout from "../layout";
import { useParams } from "react-router-dom"
import SectionSliderPosts from "components/Sections/SectionSliderPosts"
import SectionSliderCards from "components/Sections/SectionSliderCard"
import { BACKEND_URL } from "../../../../constants"

const PageSingleAudio = () => {
  const params = useParams()
  const [data,setData] = useState([])
  const [podcast, setPodcast] = useState()
  const [articles,setArticles] = useState([])
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/podcasts/${+params.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setPodcast(response.result[0])
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, []);

  console.log(podcast, "podcast")

  return (
    <>
     {/* <Layout data={data}> */}
      <div style={{display:"flex", flexFlow:"column", alignItems:"baseline", justifyContent:"baseline"}} className={`nc-PageSingle pt-8 lg:pt-16`}>
        <header className="container single-responsive">
          <div>
            <SingleHeader data={data} />
          </div>
        </header>
        <div className="container single-responsive">
           <h2 style={{maxWidth:"1371px", fontSize:"18px", fontWeight:"bold", fontFamily:'Roboto', textAlign:'justify'}}>{podcast?.title}</h2>
           <p style={{maxWidth:"1371px", fontSize:"18px", fontFamily:'Roboto', textAlign:'justify'}}>{podcast?.description}</p>
          <div style={{display:"flex", gap: '36px', flexFlow:"column", alignItems:"flex-start"}}>
        <video src={podcast?.videoUrl} poster={podcast?.image} controls width={1000} height={900} />
        {/* <span className="category-titles" style={{color:"grey",fontWeight:700}}>{data[0]?.date != null && new Date(data[0]?.date).toLocaleString()} </span> */}
          </div>
        </div>

        {/* FEATURED IMAGE */}
        {/* <div> */}
        <div className="container">
        {/* <SectionSliderCards
          className="py-16"
          postCardName="card10"
          heading="Zobacz też"
          subHeading="Inne Artykuły"
          posts={filtered
            .slice(filtered.length - 7, filtered.length)
            .filter((article) => article.id != params.id)
            .reverse()}
        /> */}
        </div>
        {/* </div> */}
      </div>
      </>
  );
};

export default PageSingleAudio;

import Card16Podcast from "components/Card16Podcast/Card16Podcast";
import Card17Podcast from "components/Card17Podcast/Card17Podcast";
import Card20Podcast from "components/Card20Podcast/Card20Podcast"
import Heading from "components/Heading/Heading";
import { DEMO_POSTS_AUDIO } from "data/posts";
import { PostDataType } from "data/types";
import React, { FC } from "react";

const SectionSliderAudio = ({
  podcasts,
  className = "",
}) => {
  const filterone = podcasts
  .filter((audio) => audio.podcasts_categories_id == 1)
  const filtertwo = podcasts
  .filter((audio) => audio.podcasts_categories_id == 2)
  return (
    <div style={{paddingBottom:"4rem"}} className={`nc-SectionMagazine8 relative ${className}`}>
      <Heading 
        desc={"Click on music icon and enjoy music or podcast"}
        className="mb-14 text-neutral-900 mobile-header mt-5 dark:text-neutral-50"
      >
        Podcasty
      </Heading>
      <button className="subcategory-btn" style={{backgroundColor:'red', fontSize:"20px", color:"white", marginTop:"10px", marginBottom:'10px', width:"42%", border:"none", padding:"3px 3px 3px 3px"}}>Podcasty</button>
      <div className={`grid grid-cols-1 sm:grid-cols-6 gap-6 md:gap-8`}>
        <>
        {filtertwo
        // .slice(0,2)
        .map((audio) => {
          return(
            <Card20Podcast
            key={audio.id}
            className="sm:col-span-3 lg:col-span-2"
            post={audio}
          />)
        })}
        {/* <Card20Podcast
          className="sm:col-span-3 lg:col-span-2"
          post={posts[1]}
        /> */}
        </>
        <div className="flex flex-col space-y-6 md:space-y-8 sm:col-span-6 lg:col-span-2">
          {filterone
            .slice(0,4)
            .map((p) => (
              <Card17Podcast key={p.id} post={p} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SectionSliderAudio;

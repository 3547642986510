import { Slide } from 'react-slideshow-image';
import kuchnia from "../../../images/kuchniaregionalna.jpg";
import zurek from "../../../images/zorek.jpeg";
import roladka from "../../../images/roladaslaska.jpeg";
import 'react-slideshow-image/dist/styles.css'
import { Link } from 'react-router-dom'
import HeaderFilter from 'components/Sections/HeaderFilter'
import { useEffect, useRef, useState } from 'react'
import { BACKEND_URL } from 'constants'
import SectionMagazineHealth from 'components/Sections/SectionMagazineHealth'
import SectionMagazineSecond5 from 'components/Sections/SectionMagazineSecond5'
import SectionNewspappers from 'components/Sections/SectionNewspappers'

const PageGazety = () => {
  const[data,setData] = useState([])
  const initialized = useRef(false);

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '250px'
  }


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/newspappers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response.result)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, []);

  return (
    <div className={`nc-PageArchiveAudio `}>
      {/* HEADER */}
      {/* <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
      <div className="container" style={{marginTop:"37px", textAlign:"left"}}>
        <HeaderFilter heading="Zdrowie" />
        </div>
      </div> */}
      {/* ====================== END HEADER ====================== */}

      <div className="container lg:pb-28 space-y-16 lg:space-y-28">
        <div>
          {/* <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
          </div> */}
            {/* <div className="kuchnia-box"> */}
            <SectionNewspappers heading="Gazeta" posts={data} />
            {/* {data
            .filter((article) => article.subcategory_id == 6 )
            .map((article) => {
              return(
                <div key={article.id} className="kuchnia-container">
                <div className="kitchen-content">
                  <div style={{display:"flex", flexFlow:"column", gap:"20px", alignItems:"baseline", textAlign:"left"}}>
                  <Link to={`/artykuł/${article.id}`}><h2 style={{fontSize:"32px"}} className="inline-block align-middle font-semibold ">{article.article_title}</h2></Link>
                <p style={{maxWidth:"318px", textAlign:"justify"}}>{article.description}</p>
                  </div>
                <Link to={`/artykuł/${article.id}`}>
                  <img alt="żurek" style={{maxWidth:'249px', height:"163px"}} src={article.image}/>
                  </Link>
                  </div>
                </div>
              )
            })} */}
            {/* </div> */}

        </div>

      </div>
    </div>
  );
};

export default PageGazety

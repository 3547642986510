import React, { HTMLAttributes, ReactNode } from "react";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  isCenter?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  desc = "",
  className = "md:mb-12 text-neutral-900 dark:text-neutral-50",
  isCenter = false,
  ...args
}) => {
  return (
    <div 
      className={`nc-Section-Heading relative podcasts-divider flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
    >
      <div
        className="desktop-container"
      >
        {children == "Aktualności z regionu" || children == "Wydarzenia dnia" ||  children == "Polityka na świecie" || children == "Zobacz też" ? <hr className="line-left" style={{padding:"2px", width:"20%", justifyContent:"flex-start", backgroundColor:"#ac8502"}}></hr>: <hr className="line-left" style={{padding:"2px", backgroundColor:"#ac8502"}}></hr>}
        <h2
          className={`font-mobile font-semibold`}
          {...args}
        >
          {children || `Section Heading`}
        </h2>
      { children == "Aktualności z regionu" || children == "Wydarzenia dnia" || children == "Polityka na świecie" || children == "Zobacz też" ? <hr className="line-right" style={{padding:"2px", width:"20%", backgroundColor:"#ac8502"}}></hr>: <hr className="line-right" style={{padding:"2px", backgroundColor:"#ac8502"}}></hr>} 
        {/* {desc && (
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            {desc}
          </span>
        )} */}
      </div>
    </div>
  );
};

export default Heading;

import React, { FC, useState } from "react";
import { SectionMagazine1Props } from "./SectionMagazine1";
import Card19 from "components/Card19/Card19";
import Card18 from "components/Card18/Card18";
import cogoToast from "cogo-toast"
import { useNavigate } from "react-router-dom"
import { BACKEND_URL } from "constants"
import ReactPaginate from 'react-paginate';
import Card18Adverts from "components/Card18Adverts/Card18"

// export interface SectionMagazine10Props extends SectionMagazine1Props {}

const SectionAdverts = ({
  posts,
  className = "",
}) => {
  const navigate = useNavigate()

  const onEdit = (id) => {
    navigate(`/edit-adverts/${id}`)
  }
  return (
    <div className={`nc-SectionMagazine10 ${className}`}>
      <div className="grid grid-cols-1 xl:grid-cols-4 gap-5">
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-5 gap-5"> */}
          {posts
            .map((item, index) => {
              return(
                <div style={{display:'flex', flexFlow:"column"}} key={item.id}>
                  <h3 style={{marginBottom:'20px', fontSize:'20px'}}>{item.article_title}</h3>
                <Card18Adverts
                  // showCategories={false}
                  imgClassName="max-height-220"
                  key={index}
                  post={item}
                />
                <span style={{textAlign:"center", fontSize:"20px", fontWeight:"bold", marginBottom:"10px", marginTop:"10px"}}>{item.section_id == 1 ? "Aktualności" : item.section_id == 2 ? "Aktualności w regionie" : item.section_id == 3 ? "Wydarzenia dnia" : item.section_id == 4 ? "Sekcja dwa kwadraty" : item.section_id == 4 ? "Sekcja dwa kwadraty": item.section_id == 5 ? "Kwadraty pod menu" : item.section_id == 6 ? "Bądz na bieżąco": item.section_id == 7 ? "Popularne artykuły" : "" }</span>
              <div style={{display:"flex", flexFlow:"column", justifyContent:"space-between"}}>
              <button onClick={() => onEdit(item.id)} style={{marginTop:"10px", fontSize:"14px", width:"100%", backgroundColor:"blue"}} className="nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-primary-700 hover:bg-primary-6000 text-primary-50 text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6">Edytuj reklamę</button>
              {/* <button onClick={() => showArticleNews(item, index )} style={{marginTop:"10px", width:"100%", fontSize:"14px", backgroundColor: item.show_in_news === "Ukryj artykuł"? "blue": "red"}} className="nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-primary-700 hover:bg-primary-6000 text-primary-50 text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6">{item.show_in_news === "Ukryj artykuł" ? "Pokaż artykuł w aktualnościach": "Ukryj artykuł" }</button> */}
              </div>
              </div>
            )})}
      </div>
      <div style={{marginTop:"20px", marginBottom:"20px"}}>
      {/* <ReactPaginate
        nextLabel="następna >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< poprzednia"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      /> */}
      </div>
    </div>
  );
};

export default SectionAdverts;

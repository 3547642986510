import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import cogoToast from "cogo-toast";
import { BACKEND_URL } from "../../../constants";
import Input from "components/Input/Input";
import PublitioAPI from 'publitio_js_sdk'
import ButtonPrimary from "components/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";
import Datetime from 'react-datetime';
import 'moment/locale/pl';
import ClipLoader from "react-spinners/ClipLoader";
import "react-datetime/css/react-datetime.css";
import Layout from "../layout";

const PageAddPodcast = () => {
  const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(1)
  let user = sessionStorage.getItem("user")
  const initialized = useRef(false);
  const {
    register,
    getValues,
    // formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      title: "",
      description:"",
      image: "",
      video:""
    },
  });

  // useEffect(() => {
  //   if (!initialized.current) {
  //     initialized.current = true;
  //     fetch(`${BACKEND_URL}/audio_categories`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((response) => {
  //         if (!response.hasOwnProperty("error")) {
  //          setCategories(response)
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("error: ", error);
  //       })
  //       .finally(() => {
  //         console.log("finally")
  //       })
  //   }
  // }, [JSON.parse(user)?.user.id, user]);


  const onChange = (e) => {
      setStatus(e.target[e.target.selectedIndex].value)
  }

  const addPodcast = async (e) => {
    setLoading(true)
    // let user = sessionStorage.getItem("user");
    const data = getValues();
    let photo;
    let video;
    e.preventDefault();
    photo = await publitio.uploadFile(data.image[0], 'file')
    video = await publitio.uploadFile(data.video[0], 'file')
    setTimeout(() => {
      fetch(`${BACKEND_URL}/add-podcasts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          title: data.title,
          description:data.description,
          status: status,
          image:photo.url_preview,
          video:video.url_preview,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            setLoading(false)
            cogoToast.success("Podcast został dodany");
            window.location.reload()
          }
          else if(response.statusCode === 400) {
            cogoToast.error("Wystąpił jakiś błąd");
          } 
        })
        .catch((error) => console.error("error: ", error));
    }, [2000]);
  };
  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
        <Heading2>Dodaj Podcasty</Heading2>
        {/* <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
         Witamy
        </span> */}
      </header>

      <div className="max-w-md mx-auto space-y-6">
        {/* FORM */}
        <form className="grid grid-cols-1 gap-6" action="#" method="post">
          <label className="block">
            <Input
             {...register("title", {
              required: { value: true, message: "Pole wymagane" },
            })}
              type="text"
              placeholder="Dodaj tytuł podcastu"
              className="mt-1"
            />
          </label>
          <label className="block">
            <Input
             {...register("description", {
              required: { value: true, message: "Pole wymagane" },
            })}
              type="text"
              placeholder="Dodaj opis do podcastu"
              className="mt-1"
            />
          </label>
          {/* <label className="block">
            <select name="kategorie" onChange={onChange} style={{borderRadius:"9999px"}}>
            <option>Wybierz kategorie</option>
            {categories
            .map((category, index) => {
              return <option id={category.id} key={category.id} value={category.name}>{category.name}</option>
            })}
            </select>
          </label> */}
             <label className="block">
            <select onChange={onChange} name="status" style={{borderRadius:"9999px"}}>
            <option value="">Wybierz opcję</option>
            <option value={0}>Ukryj</option>
            <option value={1}>Pokaż</option>
            </select>
          </label>
          <label className="block">Dodaj zdjęcie
            <Input {...register("image", {
            })} type="file" max-size="200000" className="mt-1" />
          </label>
          <label className="block">Dodaj film
            <Input {...register("video", {
            })} type="file" max-size="200000" className="mt-1" />
          </label>
        </form>
        <ButtonPrimary onClick={addPodcast}>Dodaj</ButtonPrimary>
        {loading == true && 
        <div style={{marginTop:'40px'}}>
          <p style={{marginBottom:'20px'}}>Uwaga dodawanie podcastu może chwile potrwać zależności od wielkości wysłanego filmu.</p>
          <ClipLoader color="black" size={50} aria-label="Loading Spinner" data-testid="loader"/>
          </div>}
      </div>
    </Layout>
  );
};

export default PageAddPodcast;
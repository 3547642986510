import React, { FC } from "react";
import { PostDataType } from "data/types";
import Link from "components/Link";
import ButtonPlayMusicPlayer from "../ButtonPlayMusicPlayer";
import Image from "components/Image";
import { PauseIcon } from "@heroicons/react/24/outline";
import { PlayIcon } from "@heroicons/react/24/solid";

// export interface Card17PodcastProps {
//   className?: string;
//   post: PostDataType;
// }

const Card17Podcast = ({ className = "", post }) => {
  const { title, href, image, postType, date, name, id } = post;
  const IS_AUDIO = postType === "audio";

  const renderDefaultBtnListen = (state) => {
    return (
      <span className="w-11 h-11 flex items-center justify-center bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer">
        {state === "playing" ? (
          <PauseIcon className="w-6 h-6" />
        ) : (
          <PlayIcon className="w-6 h-6" />
        )}
      </span>
    );
  };

  return (
    <div
      className={`nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 bg-neutral-100 dark:bg-neutral-800/30 hover:shadow-lg transition-shadow ${className}`}
    >
      <Link 
      href={`/podcast/${id}`}
       className="flex items-center space-x-4">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative overflow-hidden shadow-lg">
          <Image
            sizes="(max-width: 600px) 480px, 800px"
            className="object-cover"
            src={image}
            fill
            alt={title}
          />
        </div>
        <div className="flex flex-col flex-grow">
          <h2 className={`block font-medium sm:font-semibold sm:text-lg`}>
            <span className="line-clamp-1" title={title}>
              {title}
            </span>
          </h2>
          <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">
            {name}
          </span>
        </div>
      </Link>

      {IS_AUDIO && (
        <ButtonPlayMusicPlayer
          post={post}
          renderDefaultBtn={() => renderDefaultBtnListen()}
          renderPlayingBtn={() => renderDefaultBtnListen("playing")}
        />
      )}
    </div>
  );
};

export default Card17Podcast;

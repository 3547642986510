import { Slide } from 'react-slideshow-image';
import kuchnia from "../../../images/kuchniaregionalna.jpg";
import zurek from "../../../images/zorek.jpeg";
import roladka from "../../../images/roladaslaska.jpeg";
import 'react-slideshow-image/dist/styles.css'
import { Link } from 'react-router-dom'
import HeaderFilter from 'components/Sections/HeaderFilter'
import { useEffect, useRef, useState } from 'react'
import { BACKEND_URL } from 'constants'
import SectionKitchen from 'components/Sections/SectionKitchen'

const PageKuchnia = () => {
  const[data,setData] = useState([])
  const initialized = useRef(false);

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '250px'
  }


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/articles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, []);

  return (
    <div className={`nc-PageArchiveAudio `}>
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
      <div className="container mobile-header" style={{marginTop:"37px", textAlign:"left"}}>
        <HeaderFilter heading="Kuchnia" />
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container lg:pb-28 space-y-16 lg:space-y-28">
        <div>
          {/* <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
          </div> */}
            <div>
           <SectionKitchen posts={data}/>
            </div>

        </div>

      </div>
    </div>
  );
};

export default PageKuchnia

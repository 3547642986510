import React, { useEffect, useRef, useState } from "react";
import NcImage from "components/NcImage/NcImage";
import SingleHeader from "app/(singles)/SingleHeader";
import Layout from "../layout";
import {useParams } from "react-router-dom"
import { BACKEND_URL } from "constants"
import SectionSliderKitchen from "components/Sections/SectionSliderKitchen"

const PageSingle = () => {
  const params = useParams()
  const [data,setData] = useState([])
  const [articles,setArticles] = useState([])
  const initialized = useRef(false);
  const text = data[0]?.long_description

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/article/${+params.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, []);

  useEffect(() => {
      fetch(`${BACKEND_URL}/articles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setArticles(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, []);

  return (
    <>
     {/* <Layout data={data}> */}
      <div className={`nc-PageSingle pt-8 lg:pt-16`}>
        <header className="container rounded-xl">
          <div className="mx-auto">
            <SingleHeader data={data} />
          </div>
        </header>

        {/* FEATURED IMAGE */}
        {/* <div> */}
        <div className="container single-responsive">
        {/* <p style={{maxWidth:"1371px", textAlign:'justify'}}>{data[0]?.long_description.slice(0,1196)}</p> */}
          <div style={{display:"flex", gap: '36px', flexFlow:"column", alignItems:"flex-start"}}>
          {data[0]?.image == undefined && <span></span>}
         {data[0]?.type == "image" && data[0]?.image != undefined &&
          <NcImage
          alt="image"
          containerClassName=""
          className="w-full image-resp"
          src={data[0]?.image}
          width={1000}
          height={900}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />}
        {data[0]?.type == "wideo" && <video poster={data[0]?.image} controls width={1000} height={900} src={data[0]?.video}/>}
         {/* <NcImage
          style={{width:"100%"}}
          alt="single"
          containerClassName=""
          className="w-full"
          src={data[0]?.image}
          width={1000}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        /> */}
          </div>
        <p style={{maxWidth:"1371px", whiteSpace:"pre-wrap", fontSize:'20px', fontFamily:'Times New Roman', textAlign:'justify'}} dangerouslySetInnerHTML={{__html:text}}></p>
        </div>
        <div className="container">
        <SectionSliderKitchen
          className="py-16"
          postCardName="card10"
          heading="Zobacz też"
          subHeading="Inne Artykuły"
          posts={articles
            .filter((article) => article.category_id == 6 && article.id != params.id)
          }
        />
        </div>
        {/* </div> */}
      </div>
      </>
  );
};

export default PageSingle;

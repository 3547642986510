import React, { FC } from "react";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import { PostDataType } from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import musicWave from "images/musicWave.png";
import Link from "components/Link";
import Image from "components/Image";
import ButtonPlayMusicPlayer from "../ButtonPlayMusicPlayer";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";

const Card20Podcast = ({
  className = "h-full",
  post,
  ratio = "",
}) => {
  const { id, title, description, image, videoUrl } = post;

  const renderListenButtonDefault = (state) => {
    return (
      <div
        className={`w-14 h-14 flex items-center justify-center bg-neutral-50 text-primary-500 cursor-pointer`}
      >
        {state === "playing" ? (
          <PauseIcon className="w-8 h-8" />
        ) : (
          <PlayIcon className="ml-0.5 w-8 h-8" />
        )}
      </div>
    );
  };

  return (
    <div className={`nc-Card16Podcast relative flex flex-col ${className}`}>
      <Link
        // href={`/podcast/${id}`}
        className={`block flex-shrink-0 relative w-full overflow-hidden ${ratio}`}
      >
        <video
          alt=""
          style={{height:"224px", width:"392px"}}
          controls
          poster={image}
          // sizes="(max-width: 600px) 480px, 800px"
          src={videoUrl}
          className="object-cover"/>
        <span className="bg-neutral-900 bg-opacity-30"></span>
      </Link>

      {/* ABSOLUTE */}
      {/* <Link 
      href={href}
      className="absolute inset-0"></Link> */}
      {/* <span className="absolute top-3 inset-x-3">
        <CategoryBadgeList categories={categories} />
      </span> */}

      {/* MAIN CONTENT */}
      <div>
        <div
          className={`px-5 flex items-center space-x-4`}
        >
          {/* <div className={`flex-grow `}>
            <Image src={musicWave} alt="musicWave" />
          </div> */}
          {/* <ButtonPlayMusicPlayer
            post={post}
            renderDefaultBtn={() => renderListenButtonDefault()}
            renderPlayingBtn={() => renderListenButtonDefault("playing")}
          /> */}
        </div>
        <div style={{marginTop:"0rem", maxWidth:"391px"}} className="p-5 bg-white dark:bg-neutral-900 shadow-xl dark:shadow-2xl rounded-tl-none flex flex-col flex-grow ">
          <h2 className="nc-card-title block sm:text-lg lg:text-xl font-semibold text-neutral-900 dark:text-neutral-100 ">
            <Link
              href={`/podcast/${id}`} title={title}>
              {title}
            </Link>
          </h2>
           <span style={{marginBottom:"20px"}} className="block text-sm text-neutral-500 dark:text-neutral-400">
            <span style={{overflow:'visible', WebkitLineClamp:"none"}} className="line-clamp-2">{description}</span>
          </span> 
          {/* <h3 style={{color:"#ab850f", fontSize:"16px"}} className="block text-sm text-neutral-500 dark:text-neutral-400">
            <span className="line-clamp-2">{name}</span>
          </h3>  */}
          {/* <div className="flex items-end justify-between mt-auto">
            <PostCardLikeAndComment className="relative" />
            <PostCardSaveAction className="relative" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Card20Podcast;

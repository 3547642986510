import {
  MeetingConsumer,
  Constants,
  MeetingProvider,
  useMeeting,
} from "@videosdk.live/react-sdk"
import React, {useEffect, useMemo, useRef} from "react"
import NcPlayIcon2 from "components/NcPlayIcon2/NcPlayIcon2"
import Hls from "hls.js"
import {authToken} from "./api"
import cogoToast from "cogo-toast"

const HLSPlayer = ({meetingId, video, setVideo}) => {
  const {hlsUrls, hlsState} = useMeeting()

  const playerRef = useRef(null)

  const hlsPlaybackHlsUrl = useMemo(() => hlsUrls.playbackHlsUrl, [hlsUrls])

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls({
        capLevelToPlayerSize: true,
        maxLoadingDelay: 4,
        minAutoBitrate: 0,
        autoStartLoad: true,
        defaultAudioCodec: "mp4a.40.2",
      })

      let player = document.querySelector("#hlsPlayer")

      hls.loadSource(hlsPlaybackHlsUrl)
      hls.attachMedia(player)
    } else {
      if (typeof playerRef.current?.play === "function") {
        playerRef.current.src = hlsPlaybackHlsUrl
        playerRef.current.play()
      }
    }
  }, [hlsPlaybackHlsUrl, hlsState])

  return (
    <div>
      <video
        className="live-video"
        ref={meetingId != undefined ? playerRef : null}
        id="hlsPlayer"
        autoPlay
        controls
        style={{height: "70%"}}
        playsInline
        playing
        onError={err => console.log(err, "hls video error")}
      ></video>
    </div>
  )
}

const ViewerScreenContainer = ({meetingId, video, setVideo}) => {
  return (
    <>
      {meetingId != undefined && video == true ? (
        <div>
          <MeetingProvider
            token={authToken}
            config={{meetingId, name: "C.V. Raman", mode: "VIEWER"}}
            joinWithoutUserInteraction
          >
            <MeetingConsumer>
              {({hlsState}) =>
                hlsState === Constants.hlsEvents.HLS_PLAYABLE &&
                meetingId.length && <HLSPlayer meetingId={meetingId} />
              }
            </MeetingConsumer>
          </MeetingProvider>
        </div>
      ) : (
        <div style={{position: "relative"}}>
          <button
            style={{
              marginTop: "10px",
              zIndex: "100",
              top: "20%",
              left: "40%",
              position: "absolute",
            }}
            onClick={() => {
              setVideo(true)
              !meetingId &&
                cogoToast.info(
                  `W tej chwili nie ma nagrania. Jeśli będzie nagranie to ikonka "Play" podświetli się na czerwono.`,
                  {
                    hideAfter: 7,
                  }
                )
            }}
          >
            <NcPlayIcon2 live={meetingId} />
          </button>
          <video
            style={{height: "70%"}}
            controls
            className="live-video"
            poster="https://marekxd.publit.io/file/LIVE-1.jpg"
            src={undefined}
          ></video>
        </div>
      )}
    </>
  )
}

export default ViewerScreenContainer

import React, { FC, useState } from "react";
import { SectionMagazine1Props } from "./SectionMagazine1";
import Card19 from "components/Card19/Card19";
import Card18 from "components/Card18/Card18";
import cogoToast from "cogo-toast"
import { useNavigate } from "react-router-dom"
import { BACKEND_URL } from "constants"
import ReactPaginate from 'react-paginate';
import Card18Movies from "components/Card18Movies/Card18Movies"

// export interface SectionMagazine10Props extends SectionMagazine1Props {}

const SectionMagazine12Movies = ({
  movies,
  className = "",
}) => {
  const navigate = useNavigate()
  const [itemOffset, setItemOffset] = useState(5)

  const onEditMovies = (item) => {
    window.location.href = `/edit-articles/${item.id}`
  }

  const onDelete = (id) => {
    setTimeout(() => {
      fetch(`${BACKEND_URL}/delete-movies`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: id
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Film został usunięty");
            window.location.reload()
          } 
        })
        .catch((error) => console.error("error: ", error));
    }, [2000]);
  }

  const onShowMovies = (item) => {
      fetch(`${BACKEND_URL}/change-status-movies`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id:item.id,
          status: item.status == 0 ? 1: 0
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Film zmienił status");
            window.location.reload()
          } 
        })
        .catch((error) => console.error("error: ", error));
  }

  return (
    <div className={`nc-SectionMagazine10 ${className}`}>
      {!movies.length && <span>Nic nie znaleziono!</span>}
      <div className="grid grid-cols-1 xl:grid-cols-4 gap-5">
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-5 gap-5"> */}
          {movies
            .slice(0, movies.length)
            .reverse()
            .map((item, index) => {
              return(
                <div style={{display:'flex', flexFlow:"column"}} key={item.id}>
                  <h3 style={{marginBottom:'20px', fontSize:'20px'}}>{item.article_title}</h3>
                <Card18Movies
                  // showCategories={false}
                  imgClassName="max-height-220"
                  key={index}
                  post={item}
                />
                {/* <span style={{textAlign:"center", fontSize:"20px", fontWeight:"bold", marginBottom:"10px", marginTop:"10px"}}>{item.section_id == 1 ? "Aktualności" : item.section_id == 2 ? "Aktualności w regionie" : item.section_id == 3 ? "Wydarzenia dnia" : item.section_id == 4 ? "Sekcja dwa kwadraty" : item.section_id == 4 ? "Sekcja dwa kwadraty": item.section_id == 5 ? "Kwadraty pod menu" : item.section_id == 6 ? "Bądz na bieżąco": item.section_id == 7 ? "Popularne artykuły" : "" }</span> */}
              <div style={{display:"flex", flexFlow:"column", justifyContent:"space-between"}}>
              <button onClick={() => onDelete(item.id)} style={{marginTop:"10px", fontSize:"14px", width:"100%", backgroundColor:"red"}} className="nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-primary-700 hover:bg-primary-6000 text-primary-50 text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6">Usuń film</button>
              <button onClick={() => onShowMovies(item)} style={{marginTop:"10px", fontSize:"14px", width:"100%", backgroundColor:item.status == 0 ? "green": "blue"}} className="nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-primary-700 hover:bg-primary-6000 text-primary-50 text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6">{item.status == 1 ? "Ukryj film": "Pokaż film" }</button>
              <button onClick={() => onEditMovies(item)} style={{marginTop:"10px", fontSize:"14px", width:"100%", backgroundColor:"orange"}} className="nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-primary-700 hover:bg-primary-6000 text-primary-50 text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6">Edytuj film</button>
              </div>
              </div>
            )})}
      </div>
      <div style={{marginTop:"20px", marginBottom:"20px"}}>
      {/* <ReactPaginate
        nextLabel="następna >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< poprzednia"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      /> */}
      </div>
    </div>
  );
};

export default SectionMagazine12Movies;

import React, { FC } from "react";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import { PostDataType } from "data/types";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import PostFeaturedMedia from "components/PostFeaturedMedia/PostFeaturedMedia";
import Link from "components/Link";
import Image from "components/Image";

// export interface Card18Props {
//   className?: string;
//   ratio?: string;
//   titleClass?: string;
//   post: PostDataType;
//   hoverClass?: string;
//   showCategories?: boolean;
// }

const Card18Adverts = ({
  className = "h-full",
  titleClass = "text-lg ",
  ratio = "aspect-w-4 sm:aspect-w-3 aspect-h-3",
  post,
  hoverClass = "",
  // showCategories = true,
}) => {
  const { name, photo, description } = post;

  const renderMeta = () => {
    return (
      <div style={{flexFlow:"column",alignItems:"baseline"}} className="inline-flex items-center text-xs text-neutral-300">
        <h2 className={`block font-semibold text-white ${titleClass}`}>
          {name}
        </h2>
        <p>{description}</p>
      </div>
    );
  };

  return (
    <div
      className={`nc-Card18  ${hoverClass} ${className}`}
    >
      {/* <div className="absolute inset-x-0 top-0 p-3 flex items-center justify-between transition-all opacity-0 z-[-1] group-hover:opacity-100 group-hover:z-10 duration-300">
        <PostCardLikeAndComment className="relative" />
        <PostCardSaveAction className="relative" />
      </div> */}
      <div className={`flex items-start relative w-full`}></div>
        <Link to="/single" >
          <Image
            sizes="(max-width: 600px) 480px, 800px"
            alt="featured"
            className="object-contain w-full h-full"
            src={photo}
            fill
          />
          {/* <PostTypeFeaturedIcon
            className="absolute top-3 left-3 group-hover:hidden"
            // postType={postType}
            wrapSize="w-7 h-7"
            iconSize="w-4 h-4"
          />
          <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span> */}
        </Link>
      {/* <Link
        className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black opacity-80"
      ></Link> */}
      {/* <div className="absolute bottom-0 inset-x-0 p-6 flex flex-col flex-grow">
        <Link className="absolute inset-0"></Link>
        {showCategories && (
          <div className="mb-3">
            <CategoryBadgeList categories={categories} />
          </div>
        )}
        <Link to = "/single">{renderMeta()}</Link>
      </div> */}
    </div>
  );
};

export default Card18Adverts;

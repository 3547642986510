import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";
import { useSwipeable } from "react-swipeable";
import { variants } from "utils/animationVariants";
import { AnimatePresence, motion, MotionConfig, useAnimation, useMotionValue } from "framer-motion";
import PrevBtn from "components/NextPrev/PrevBtn";
import NextBtn from "components/NextPrev/NextBtn";
import { Link } from "react-router-dom"

export default function MySlider({
  data,
  width,
}) {
  const controls = useAnimation();
  const x = useMotionValue(0);
  const ITEM_WIDTH =  150; // szerokość pojedynczego elementu
  const GAP = 50; // przerwa między elementami
  const TOTAL_ITEMS = data.length * 2; // bo duplikujemy
  const TOTAL_WIDTH = TOTAL_ITEMS * (ITEM_WIDTH + GAP) || width;
  const BASE_DURATION = 10; // czas pełnego obiegu

  const startAnimation = (fromX = 0) => {
    const distanceLeft = TOTAL_WIDTH / 2 + fromX;
    const duration = (distanceLeft / (TOTAL_WIDTH / 2)) * 25;


    controls.start({
      x: [-fromX, -TOTAL_WIDTH / 2],
      transition: {
        duration,
        ease: "linear",
        repeat: Infinity,
      },
    });
  };

  useEffect(() => {
    startAnimation(0); // ruszamy od razu po załadowaniu
  }, []);

  const handleMouseEnter = () => {
    controls.stop();
  };

  const handleMouseLeave = () => {
    const currentX = x.get() % (TOTAL_WIDTH / 2)
    startAnimation(-currentX);
  };

  return (
    <div
      className="relative overflow-hidden"
      // style={{
      //   maskImage:
      //     "linear-gradient(to right, transparent, black 10%, black 90%, transparent)",
      //   WebkitMaskImage:
      //     "linear-gradient(to right, transparent, black 10%, black 90%, transparent)",
      // }}
    >
      <motion.ul
        className="flex mobile-bandwidth"
        style={{ x, listStyleType:'disc'
       }}
        animate={controls}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {[...data, ...data].map((item, index) => (
          <li
            key={index}
            className="w-full py-2 rounded hover-list"
          >
            <Link style={{color:"white", fontWeight:'bold',fontSize:"11px", fontFamily:'Redaction', whiteSpace: 'nowrap' }} to={`/${item?.title.toLowerCase()}/${item?.name.toLowerCase()}/${item?.category_id}/${item?.subcategory_id}/artykuł/${item?.id}`}>{item.article_title}</Link>
          </li>
        ))}
      </motion.ul>
    </div>
  );
}

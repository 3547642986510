import React, { useEffect, useRef, useState } from "react";
import SectionMagazineCategories from "components/Sections/SectionMagazineCategories"
import { useParams } from "react-router-dom"
import { Slide } from 'react-slideshow-image';
import horoskop from "../../../images/horoskop.jpg"
import 'react-slideshow-image/dist/styles.css'
import HeaderFilter from "components/Sections/HeaderFilter"

const PageHoroskop = () => {
  const [data,setData] = useState([])
  const initialized = useRef(false);


  const spanStyle = {
    padding: '20px',
    background: "none",
    color: 'white',
    fontSize:"50px"
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '250px'
  }

  const slideImages = [
    // {
    //   url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    //   caption: "Horoskop"
    // },
    // {
    //   url:"https://i.ibb.co/LttdKXB/pexels-sharefaith-491036-1202723.jpg",
    //   caption: "Horoskop"
    // },
    {
      url: horoskop,
      caption: "Horoskop"
    },
  ]; 

  return (
    <div className={`nc-PageArchiveAudio `}>
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="container" style={{marginTop:"37px", textAlign:"left"}}>
          <HeaderFilter heading="Horoskop"/>
        {/* <Slide>
         {slideImages.map((slideImage, index)=> (
            <div className="container" key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
              </div>
            </div>
          ))} 
        </Slide> */}
          {/* <div className="absolute inset-0 text-white flex flex-col items-center justify-center">
            <h2 style={{fontSize:"50px"}} className="inline-block align-middle ml-3">
              Horoskop
            </h2>
          </div> */}
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container lg:pb-28 space-y-16 lg:space-y-28">
        <div>
          {/* <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
          </div> */}
              <h2>Marzec 2025</h2>
            <div className="horoskop-container">
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Baran 21 marca do 19 kwietnia
                </h4>
                <p>
                Mając na względzie wydarzenia, które zajdą w twoim życiu od marca do końca kwietnia, poukładaj wszystkie swoje sprawy, zanim 15 kwietnia Merkury wejdzie w retrogradację. 29 marca wystąpi pierwsze od prawie dekady zaćmienie Słońca w twoim znaku, więc jeśli chcesz rozpocząć nową, wielką przygodę, będzie to idealny moment. W każdym razie czeka cię nowy rozdział w życiu. Neptun wkroczy do twojego znaku 30 marca, więc można ci wybaczyć, jeśli żyjesz w bańce miłości i (lub) w lekkim oderwaniu od rzeczywistości. Ze względu na ten stan zabezpiecz się i domagaj się wszystkiego na piśmie i pomyśl dwa razy, zanim powiesz „tak” lub „nie”.
            </p>
            </div>
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Byk 20 kwietnia do 22 maja
                </h4>
                <p>
                Jeśli myślisz, że nic cię już nie zaskoczy, to się mylisz. W ciągu najbliższych sześciu tygodni wydarzy się mnóstwo kompletnie niespodziewanych rzeczy. Ten niezwykły astralny krajobraz utrzyma się jeszcze przez cały kwiecień. Przyjaźnie i współprace nadal będą ważne, niezależnie od tego, czy szczęśliwy czas się skończył i jest wiele do uporządkowania, czy też dlatego, że zostało potwierdzone nowe przedsięwzięcie i trzeba zrobić dużo w krótkim czasie. 15 marca Merkury wejdzie w retrogradację, więc komunikacja nie będzie łatwa. Może ci się wydawać, że płyniesz pod prąd, ale w końcu wszystko się ułoży.
            </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Bliźnięta 23 maja do 21 czerwca
                </h4>
                <p>
                Co się stało? Będziesz to często powtarzać w ciągu najbliższych kilku tygodni. Na szczęście jesteś jednym z najbardziej elastycznych – nie, pomysłowych! – znaków, więc zapewne zdołasz całkowicie odwrócić sytuację. 14 marca całkowite zaćmienie Księżyca w koniunkcji z Saturnem to tylko część bardzo złożonego obrazu i może się okazać, że nie masz innego wyboru, jak tylko zaakceptować sposób, w jaki sprawy się ułożą. Nie cofniesz tego, co już się wydarzyło. I z pewnością nie można uciec przed konsekwencjami swoich czynów. Więcej informacji pojawi się w kwietniu, więc na razie najlepiej powstrzymać się od działania.
            </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Rak 22 czerwca do 22 lipca
                </h4>
                <p>
                Ten tydzień w pracy przyniesie Ci nowe możliwości. Zwróć uwagę na pojawiające się okazje – mogą one znacząco wpłynąć na Twoją zawodową przyszłość. Nie bój się podejmować decyzji, które wydają się ryzykowne. Twoja intuicja podpowie Ci, co zrobić. Twoje zdrowie będzie dobre, o ile zadbasz o odpowiednią regenerację. Grudzień to idealny czas, by wprowadzić zdrowe nawyki – może krótkie spacery lub nowe przepisy kulinarne? Horoskop przestrzega przed przeciążeniem – nadmiar obowiązków może odbić się na Twoim samopoczuciu. Relacje międzyludzkie będą wymagały od Ciebie większej uwagi. Bliscy mogą potrzebować Twojego wsparcia, szczególnie emocjonalnego. Pamiętaj, że Twoja empatia i ciepło są bezcenne – znajdź czas, by okazać swoją troskę, zanim grudniowe przygotowania pochłoną Was wszystkich.
              </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Lew 23 lipca do 23 sierpnia
                </h4>
                <p>
                Im większy wysiłek, tym większa nagroda. W marcu trzeba się będzie sporo natrudzić, żeby dotrzeć do celu, zarówno w rozumieniu podróży, jak i realizacji ambicji. Pojawią się nieoczekiwane przeszkody do pokonania, niektóre związane z awariami systemu, inne wynikające z nieporozumień. Ale nie poddawaj się, uda ci się. Zaćmienie Księżyca 14 marca kończy cykl dotyczący finansów – można będzie zawierać transakcje i spłacić długi – natomiast zaćmienie Słońca 29 marca to zapowiedź wydarzeń, które zmienią twoje horyzonty, niezależnie od tego, czy twoja przyszłość jest związana z odległym miejscem, czy z innym kierunkiem drogi.
                </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Panna 24 sierpnia do 22 września
                </h4>
                <p>
                To jeden z najważniejszych miesięcy 2025 roku. 14 marca zaćmienie Księżyca w twoim znaku oznacza okres zamknięcia i dopełnienia. Można zawierać małżeństwa, partnerstwa i podejmować zobowiązania, ale jest to również czas, gdy napięte sytuacje osiągają punkt krytyczny, co wystawia niektóre związki na próbę. To, co wydarzy się w marcu, zależy nie tylko od losu, ale i od twojej reakcji. W takich chwilach ludzie ujawniają swoje prawdziwe oblicze i może cię zaskoczyć, kto spełnił oczekiwania, a kto nie. 
                </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Waga 23 września do 22 października
                </h4>
                <p>
                Tygodnie mijają i nic szczególnego się nie zdarza, aż nagle dzieje się mnóstwo. Marzec jest właśnie taki. W sekundę przyspieszysz od zera do setki. Do tego, co nieoczekiwane, trudno się przygotować, ale można uważnie obserwować aspekty swojego życia kluczowe dla dobrego samopoczucia. Zajmij się małym problemem, zanim stanie się gigantyczny! Praca może być obszarem nieplanowanych i bezprecedensowych zdarzeń, niezależnie od tego, czy otrzymasz fantastyczną ofertę, czy obejmie cię restrukturyzacja. Ponieważ Saturn jest zaangażowany w sprawy, czas i karma odgrywają tu dużą rolę.
                </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Skorpion 23 października do 21 listopada
                </h4>
                <p>
                Kiedy sytuacja staje się trudna, Skorpiony zaczynają działać! Nie chodzi o to, że czeka cię zły miesiąc, ale tak dużo będzie się w nim działo, że potrzebujesz pełnej koncentracji, aby jak najlepiej wykorzystać świetne możliwości związane z rozwojem wydarzeń. Dzieci mogą sprawić, że ten okres będzie niezapomniany, ale praca i ludzie mieszkający w odległych miejscach też mogą mieć na to wpływ. Kosmiczny układ sił wywoła w tobie wielką zmianę, a ta z kolei sprawi, że ujrzysz pewnych ludzi i sytuacje w nowym świetle. Nic nie pozostanie takie samo, ty też nie.
                </p>
            </div>       
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Strzelec 22 listopada do 21 grudnia
                </h4>
                <p>
                Potrzebujesz Saturna, aby doprowadzić sprawy do porządku, więc zamiast narzekać, że zrobienie czegoś we właściwy sposób wymaga czasu, ciesz się, że masz wszystko, czego trzeba, aby dokończyć dzieła. Droga na skróty nie jest dobrym wyjściem, co mogą pokazać wydarzenia w dniach około zaćmienia Księżyca (14 marca). Obecny okres roku przyniesie to, co prawdziwe i trwałe, jeśli jednak podążasz drogą, która nie jest w twoim najlepszym interesie, możliwości rozwoju mogą się wyczerpać. Zmiana miejsca zamieszkania lub pracy również wchodzi w grę.
                </p>
            </div>        
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Koziorożec 22 grudnia do 19 stycznia
                </h4>
                <p>
                W tym miesiącu nie ma nic prostego. Są zwroty akcji i nieoczekiwane postoje. Chociaż taka jazda bez trzymanki może ci się podobać, warto przygotować plany awaryjne, zwłaszcza dotyczące podróży i w tygodniu około 10 marca. Matka natura może mieć swój udział w rozwoju wydarzeń, ale im mniej będziesz zależeć od świata cyfrowego, tym lepiej. Utwórz kopie zapasowe wszystkiego. Zaćmienie Słońca 29 marca zmieni sytuację na domowym froncie, a w ciągu najbliższych tygodni ma się otworzyć nowy rozdział.
                </p>
            </div>        
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Wodnik 20 stycznia do 18 lutego
                </h4>
                <p>
                Marzec to idealny czas, aby wyruszyć w nowym, pełnym przygód kierunku, dlatego trzymaj się tego bez względu na cudze opinie. Nie żeby to był łatwy miesiąc. Dwa zaćmienia wprowadzają element przeznaczenia, więc trzeba będzie szybko dostosowywać się do zmiennych okoliczności, zwłaszcza około 12 i 30 marca. Warto również odłożyć pewną kwotę na czarną godzinę. Chociaż ten rok jest lepszy dla finansów, nie oznacza to, że możesz wydawać pieniądze, licząc na dobrą sytuację w przyszłości.
            </p>
            </div>
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Ryby 19 lutego do 20 marca
                </h4>
                <p>
                Czeka cię niezapomniany marzec. Przygotuj się na najbardziej niespodziewane i niezwykłe sytuacje. Zaćmienie Księżyca 14 marca przyniesie ważne wnioski, a to, co zdecydujesz w tych dniach, okaże się trwałe. Niektóre Ryby przypieczętują swój związek, ale Saturn może sprawić, że inne będą musiały się zmierzyć z niekorzystnymi miłosnymi realiami. Twoja karma odegra ważną rolę w nadchodzących wydarzeniach. Drugie zaćmienie 29 marca skupi się na finansach i poczuciu własnej wartości, a w ciągu najbliższych sześciu tygodni zaskakujący rozwój wypadków może odmienić twój los. Jak? O tym zadecydują gwiazdy.
            </p>
            </div>                  
            </div>

          {/* <SectionMagazineCategories
            category={params.category}
            category_id={params.id}
            posts={data}
          /> */}

        </div>

        {/* <div className="relative py-16">
          <BackgroundSection />
        </div> */}
      </div>
    </div>
  );
};

export default PageHoroskop

import { PostDataType } from "data/types";
import React, { FC } from "react";
import Badge from "components/Badge/Badge";

// export interface CategoryBadgeListProps {
//   className?: string;
//   itemClass?: string;
//   categories: PostDataType["categories"];
// }

const CategoryBadgeList = ({
  className = "flex flex-wrap space-x-2",
  itemClass,
  categories,
}) => {
  return (
    <div style={{justifyContent:"end"}}
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
        <Badge
          className={itemClass}
          color={categories?.title == "Polityka"? "blue": categories?.title == "Sport" ? "red" : categories?.title == "Społeczeństwo" ? "green": categories?.title == "Gospodarka" ? "orange": "orange"}         
          key={categories?.id}
          name={categories?.title}
          href={`/${categories?.title}/${categories?.name}/${parseInt(categories?.subcategory_id)}/${categories?.category_id}`}
        />
    </div>
  );
};

export default CategoryBadgeList;
